import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Tables from "../../pages/customer";
import Charts from "../../pages/SimCards";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var userType = sessionStorage.getItem("userType");
  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar userType={userType} />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          {userType === "ADMIN" && (
            <Switch>
              <Route path="/app/customer" component={Tables} />
              <Route path="/app/SimCards" component={Charts} />
            </Switch>
          )}
          {userType === "CUSTOMER" && (
            <Switch>
              <Route path="/app/SimCards" component={Charts} />
            </Switch>
          )}
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
