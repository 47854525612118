import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";

// styles
import useStyles from "./styles";
import {
  gql,
  useLazyQuery,
  useApolloClient,
  useMutation,
} from "@apollo/client";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import FileDownloadTwoToneIcon from "@material-ui/icons/GetAppTwoTone";

// components
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import { client2 } from "./client";
import MaxWidthDialog from "./editImei";
import Customer_Template from "../../images/Customer_Template.xlsx";
import withSnackbar from "../../components/Snackbar/withSnackbar";

const GET_UPLOAD_URL = gql`
  mutation ($fileExtension: String!) {
    getPublicUploadURL(fileExtension: $fileExtension) {
      bucketName
      filename
      publicUploadURL
    }
  }
`;

const GET_DOWNLOAD_URL = gql`
  mutation ($customerId: Int, $ownerId: Int!, $downloadFor: String!) {
    excelFileDownload(
      customerId: $customerId
      ownerId: $ownerId
      downloadFor: $downloadFor
    ) {
      downloadUrl
    }
  }
`;

const getTCUData = gql`
  query (
    $userId: Int
    $limit: Int!
    $offset: Int!
    $search: customerSearchInput
  ) {
    getCustomers(
      userId: $userId
      limit: $limit
      offset: $offset
      search: $search
    ) {
      totalCount
      rows {
        id
        userId
        name
        contactPerson
        email
        phoneNo
        username
        ownerId
        totalSim
      }
    }
  }
`;

const excelFileUpload = gql`
  mutation excelFileUpload(
    $fileInfo: FileUploadInput!
    $commonInput: CommonInput!
  ) {
    excelFileUpload(fileInfo: $fileInfo, commonInput: $commonInput) {
      failedToUpload
      totalExcelDataRecords
      totalDuplicateRecords
      successfullyUploaded
      failedUploadList
    }
  }
`;

const columns = [
  {
    id: "name",
    label: "Customer Name",
    minWidth: 100,
    align: "center",
  },
  {
    id: "name",
    label: "Contact Person",
    minWidth: 100,
    align: "center",
  },
  {
    id: "phoneNo",
    label: "Contact Number",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "email",
    label: "Contact Email",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "username",
    label: "Username",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "id",
    label: "Total Sim Associated",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: "device_added_on",
  //   label: "Device Added On",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toFixed(2),
  // },
];

function Tables(props) {
  const client = useApolloClient();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [editId, setEditId] = React.useState();
  const [oemId, setOemId] = React.useState();
  const [searchInput, setSearchInput] = useState();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getTCUInventory({
      variables: {
        OEMId: 1,
        limit: rowsPerPage,
        offset: newPage,
        status: 1,
      },
    });
  };

  let ownerId = parseInt(sessionStorage.getItem("userId"));

  const [isOpen, setIsOpen] = useState(false);
  const [isDelete, setDelete] = useState(false);

  const handleOpen = (id, oemId) => {
    setIsOpen(!isOpen);
    setEditId(id);
    setOemId(oemId);
    console.log("id---", id);
  };

  const handleDelete = (id, oemId) => {
    setIsOpen(true);
    setEditId(id);
    setOemId(ownerId);
    setDelete(true);
  };

  //const client = useApolloClient();

  let rows;

  const [readPermission, setReadPermission] = useState(true);
  const [createPermission, setCreatePermission] = useState(true);
  const [updatePermission, setUpdatePermission] = useState(true);
  const [deletePermission, setDeletePermission] = useState(false);
  const [excelData, setexcelData] = useState([]);
  const [Excelerror, setExcelerror] = useState();

  //console.log("proppsss---", props.featureId);

  let id;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getTCUInventory({
      variables: {
        ownerId: ownerId,
        limit: event.target.value,
        offset: page,
        status: 1,
      },
    });
  };

  useEffect(() => {
    if (!isOpen) {
      getTCUInventory({
        variables: {
          ownerId: ownerId,
          limit: rowsPerPage,
          offset: page,
          status: 1,
        },
      });
    }
  }, [isOpen]);

  useEffect(() => {
    getTCUInventory({
      variables: {
        ownerId: ownerId,
        limit: rowsPerPage,
        offset: page,
        status: 1,
      },
    });
  }, []);

  const [
    excelUpload,
    { loading: excelLoading, error: excelError, data: exceldata },
  ] = useMutation(excelFileUpload, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setExcelError(`Message: ${message}`);
          props.closeDialog(false);
          props.setExcelData(null);
          props.setUpload(false);
          props.setMessage(`Message: ${message}`);
          setTimeout(() => {
            props.setSnackbar(false);
          }, 3000);
        });
      }
    },
    onCompleted: (ressss) => {
      props.setMessage(
        "The file data has been uploaded. Click on 'Done' to view the result."
      );
      props.setUpload(true);

      //props.closeDialog(true);

      props.setExcelData(ressss.excelFileUpload);
      getTCUInventory({
        variables: {
          limit: rowsPerPage,
          offset: page,
          status: 1,
          ownerId: ownerId,
        },
      });
    },
  });

  console.log("Data-----", excelLoading);

  const handleSearch = (event) => {
    getTCUInventory({
      variables: {
        OEMId: props.oem,
        limit: rowsPerPage,
        offset: page,
        status: 1,
        search: {
          imei: event.target.value,
        },
      },
    });
  };

  const [
    excelDownload,
    { loading: SIMExcelLoading, error: SIMExcelError, data: SIMExcelData },
  ] = useMutation(GET_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      window.open(result.excelFileDownload.downloadUrl);
    },
  });

  const [
    getTCUInventory,
    { loading: tcuLoading, error: tcuError, data: tcuData },
  ] = useLazyQuery(getTCUData, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {},
  });
  console.log("TCUDATA-----", tcuData);
  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const onUpload = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    console.log("file----", file);
    console.log("validdiiitt---", validity);
    if (file) {
      //setUpload(true);
      //setProgressDialog(true);

      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);

      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );

        const response = await client2.mutate({
          mutation: GET_UPLOAD_URL,
          variables: {
            fileExtension,
          },
        });
        //console.log("response----", response.data);
        if (response.data && response.data.getPublicUploadURL) {
          console.log("response----", response.data);
          const url = response.data.getPublicUploadURL.publicUploadURL;

          await axios.put(url, file, {
            onUploadProgress: (data) => {
              //Set the progress value to show the progress bar
              let percentage = Math.round((100 * data.loaded) / data.total);
              props.setMessage(`Uploading Customer ${percentage} %`);
              props.setIsCustomer(true);
              props.setSnackbar(true);
            },
          });

          let UserInput = {
            uploadFor: "DeviceDetailsUpload",
            bucketName: response.data.getPublicUploadURL.bucketName,
            filename: response.data.getPublicUploadURL.filename,
          };

          let CommonInput = {
            ownerId: ownerId,
          };

          excelUpload({
            variables: {
              fileInfo: {
                uploadFor: "CustomerDetailsUpload",
                bucketName: response.data.getPublicUploadURL.bucketName,
                fileName: response.data.getPublicUploadURL.filename,
              },
              commonInput: {
                ownerId: ownerId,
              },
            },
          });
        }
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  const onDownload = (event) => {
    excelDownload({
      variables: {
        ownerId: ownerId,
        downloadFor: "CUSTOMER",
      },
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            style={{ marginTop: "10px", marginBottom: "25px" }}
            variant="h4"
          >
            Customers
          </Typography>
        </Grid>
        {tcuLoading ? (
          <CircularProgress />
        ) : (
          <>
            {readPermission ? (
              <>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={8}>
                    {createPermission ? (
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <input
                              accept="xlsx/*"
                              className={classes.input}
                              id="contained-button-file"
                              multiple
                              type="file"
                              onChange={onUpload}
                              onClick={(e) => {
                                e.target.value = null;
                              }}
                            />
                            <label htmlFor="contained-button-file">
                              <Button
                                variant="outlined"
                                className={classes.button}
                                component="span"
                                // onClick={() => setSnackbar("Downloading")}
                              >
                                Upload Template
                              </Button>
                            </label>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              href={Customer_Template}
                              variant="outlined"
                              className={classes.sampleButton}
                              download="Customer_Template"
                              //onClick={() => setSnackbar("Downloading")}
                            >
                              Download Template
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    ) : null}
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        className={classes.exportButton}
                        style={{ float: "right" }}
                        startIcon={<FileDownloadTwoToneIcon />}
                        onClick={onDownload}
                      >
                        Export
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.bod} item xs={12}>
                  {tcuLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <>
                        <TableContainer
                          className={classes.container}
                          key={tcuData}
                        >
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <>
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{
                                        minWidth: column.minWidth,
                                      }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  </>
                                ))}
                                {updatePermission || deletePermission ? (
                                  <TableCell align="center">Action</TableCell>
                                ) : null}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tcuData ? (
                                tcuData.getCustomers.rows.map((row) => {
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                    >
                                      <TableCell align="center">
                                        {row.name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.contactPerson}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.phoneNo}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.email}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.username}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.totalSim}
                                      </TableCell>
                                      {updatePermission || deletePermission ? (
                                        <TableCell align="center">
                                          {updatePermission ? (
                                            <>
                                              <Button
                                                onClick={() =>
                                                  handleOpen(row.id, row.userId)
                                                }
                                              >
                                                <CreateIcon />
                                              </Button>
                                            </>
                                          ) : null}
                                          {deletePermission ? (
                                            <>
                                              <Button
                                                onClick={() =>
                                                  handleDelete(
                                                    row.id,
                                                    row.oem.id
                                                  )
                                                }
                                              >
                                                <DeleteIcon />
                                              </Button>
                                            </>
                                          ) : null}
                                        </TableCell>
                                      ) : null}
                                    </TableRow>
                                  );
                                })
                              ) : (
                                <TableRow>
                                  <TableCell>No Records found!</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 100]}
                          component="div"
                          count={tcuData ? tcuData.getCustomers.totalCount : 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </>
                    </>
                  )}
                </Grid>
                {isOpen ? (
                  <MaxWidthDialog
                    isDialogOpened={isOpen}
                    handleCloseDialog={() => setIsOpen(false)}
                    oemTcuInventoryId={oemId}
                    ownerId={ownerId}
                    isDelete={isDelete}
                  />
                ) : null}
              </>
            ) : null}
          </>
        )}
      </Grid>
    </>
  );
}

export default withSnackbar(Tables);
