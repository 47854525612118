import React from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const columns = [
  {
    name: "ICCID",
    label: "ICCID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Primary_TSP_Name",
    label: "Primary_TSP_Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "Primary_TSP_MSISDN",
    label: "Primary_TSP_MSISDN",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "Primary_TSP_Activation",
    label: "Primary_TSP_Activation",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "Primary_TSP_Expiry",
    label: "Primary_TSP_Expiry",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "Fallback_TSP_Name",
    label: "Fallback_TSP_Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "Fallback_TSP_MSISDN",
    label: "Fallback_TSP_MSISDN",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "Fallback_TSP_Activation",
    label: "Fallback_TSP_Activation",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "Fallback_TSP_Expiry",
    label: "Fallback_TSP_Expiry",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "Card_State",
    label: "Card_State",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "Card_Status",
    label: "Card_Status",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "error",
    label: "Error",
    options: {
      filter: true,
      sort: false,
    },
  },
];

export default function AlertDialog({
  isDialogOpened,
  handleCloseDialog,
  errorData,
  excelData,
}) {
  const handleClose = () => {
    //setOpen(false);
    handleCloseDialog();
  };
  let obj;
  if (excelData.failedUploadList) {
    obj = JSON.parse(excelData.failedUploadList);
  } else {
    obj = JSON.parse(excelData.SimfailedUploadList);
  }

  const options = {
    search: true,
    download: true,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  return (
    <div>
      <Dialog
        open={isDialogOpened}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown={true}
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">{"Upload Response"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorData ? (
              errorData
            ) : (
              <>
                {excelData
                  ? (console.log("----excel----", excelData),
                    (
                      <>
                        <span>
                          Total records :{excelData.totalExcelDataRecords}
                        </span>
                        <span>
                          Successfully Uploaded:{excelData.successfullyUploaded}
                        </span>
                        <span>
                          Total Failed Records:{excelData.failedToUpload}
                        </span>
                        <span>
                          Total Duplicated Records:
                          {excelData.totalDuplicateRecords}
                        </span>
                        <MUIDataTable
                          title={"Device Upload"}
                          data={obj}
                          columns={columns}
                          options={options}
                        />
                        {/* <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <>
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  </>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {obj.map(
                                (row) => (
                                  console.log(row),
                                  (
                                    <TableRow key={row.name}>
                                      <TableCell component="th" scope="row">
                                        {row.device}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.imei}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.serialNumber}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.iccid}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.error}
                                      </TableCell>
                                    </TableRow>
                                  )
                                ),
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer> */}
                      </>
                    ))
                  : null}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
