import React from "react";

import { SnackbarConsumer } from "./Snackbar.Context";

export default (Component) => (props) => (
  <SnackbarConsumer>
    {({
      message,

      open,

      isUploaded,

      setUpload,

      setMessage,

      isDialogVisible,

      closeDialog,

      setSnackbar,
      setExcelError,
      setExcelData,
      setIsCustomer,
    }) => (
      <Component
        message={message}
        open={open}
        isUploaded={isUploaded}
        setSnackbar={setSnackbar}
        setUpload={setUpload}
        setMessage={setMessage}
        isDialogVisible={isDialogVisible}
        closeDialog={closeDialog}
        setExcelError={setExcelError}
        setExcelData={setExcelData}
        setIsCustomer={setIsCustomer}
        {...props}
      />
    )}
  </SnackbarConsumer>
);
