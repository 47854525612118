import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  pageTitle: {
    color: "#212121",
    fontSize: "2rem !important",
  },
  input: {
    display: "none",
  },
  buttonDiv: {
    marginTop: "10%",
    marginLeft: "50%",
  },
  button: {
    borderRadius: "20px !important",
    backgroundColor: "#E0FFF3",
    border: "1px solid #57B793",
    color: "#57B793",
    width: "100%",
  },

  sampleButton: {
    borderRadius: "20px !important",
    backgroundColor: "#111",
    border: "1px solid #333",
    color: "#FFF !important",
    width: "100%",
  },
  bod: {
    backgroundColor: "#FFF",
    height: "65vh",
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  footerButtonGrid: {
    marginTop: "50px !important",
    marginBottom: "20px !important",
  },
  footerButton: {
    margin: theme.spacing(1),
    borderRadius: "25px !important",
  },
  saveButton: {
    margin: theme.spacing(1),
    borderRadius: "25px !important",
    color: "#5BB996",
  },
  exportButton: {
    borderRadius: "20px !important",
    backgroundColor: "#546cf6",
    border: "1px solid #333",
    color: "#333 !important",
    marginTop: "10px",
  },
}));
