import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

//let uri = "https://dev-api.aquilatrack.com/graphql";
//let uri = "https://orsac-api.aquilatrack.com/graphql";
const httpLink = createHttpLink(
  {
    uri: "https://dev-api.aquilatrack.com/graphql",
  },
  onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
  })
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbklkIjoxLCJ1c2VybmFtZSI6InplbGlvdFNBIiwicm9sZXMiOiJTVVBFUkFETUlOIiwiaWF0IjoxNjcwNjQ5OTgyfQ.YKTTdVmHn7b5tsuGz5yBJ7BNrc8Dw1eodDaH20v77VI";
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbklkIjozOTU5LCJ1c2VybmFtZSI6ImFiaGluYXYiLCJyb2xlcyI6IkNMSUVOVCIsImlhdCI6MTY2OTIwMDgxOX0.dVhvPgwyqrgHENzaS1PYVdSvVL5KTq5Cxy8xSsbl0H0";
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client2 = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
