import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TextField,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SaveIcon from "@material-ui/icons/Save";
import ReplayIcon from "@material-ui/icons/Replay";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

// styles
import useStyles from "./styles";

const getTCUData = gql`
  query (
    $userId: Int
    $limit: Int!
    $offset: Int!
    $search: customerSearchInput
  ) {
    getCustomers(
      userId: $userId
      limit: $limit
      offset: $offset
      search: $search
    ) {
      totalCount
      rows {
        id
        userId
        name
        contactPerson
        email
        phoneNo
        username
        ownerId
      }
    }
  }
`;

const updateDevice = gql`
  mutation (
    $userId: Int!
    $name: String!
    $contactPerson: String!
    $email: String!
    $phoneNo: String!
    $Username: String!
    $status: String!
    $ownerId: Int
  ) {
    addEditCustomers(
      userId: $userId
      name: $name
      contactPerson: $contactPerson
      email: $email
      phoneNo: $phoneNo
      Username: $Username
      status: $status
      ownerId: $ownerId
    ) {
      message
    }
  }
`;

export default function MaxWidthDialog({
  isDialogOpened,
  handleCloseDialog,
  oemTcuInventoryId,
  ownerId,
  isDelete,
}) {
  console.log("ownerId---", ownerId);
  useEffect(() => {
    console.log("ldjklsdjfks");
    getTCUInventoryEdit({
      variables: {
        userId: oemTcuInventoryId,
        limit: 1,
        offset: 0,
      },
    });
  }, []);
  //const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth] = useState("md");
  const [name, setname] = useState();
  const [contactPerson, setcontactPerson] = useState();
  const [Email, setEmail] = useState();
  const [phoneNo, setphoneNo] = useState();
  const [username, setusername] = useState();
  const [password, setpassword] = useState();
  const [isUpdate, setUpdate] = useState(false);
  const [updateMessage, setUpdateMessage] = useState();
  const [deleteMessage, setDeleteMessage] = useState(
    "Do you want to delete this record"
  );
  const [isdeleted, setIsDeleted] = useState(false);
  const classes = useStyles();

  //Fetch the imei details using the id passed through props
  const [getTCUInventoryEdit, { loading, error, data }] = useLazyQuery(
    getTCUData,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        if (result) {
          setname(result.getCustomers.rows[0].name);
          setcontactPerson(result.getCustomers.rows[0].contactPerson);
          setEmail(result.getCustomers.rows[0].email);
          setphoneNo(result.getCustomers.rows[0].phoneNo);
          setusername(result.getCustomers.rows[0].username);
          //setpassword(result.getCustomers.rows[0].password);
        }
      },
    }
  );

  //Fetch the imei details using the id passed through props

  const [
    getUpdateDevice,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useMutation(updateDevice, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          setUpdate(true);
          setFullWidth(false);
          setUpdateMessage(message);
        });
      }
    },
    onCompleted: (result) => {
      if (result) {
        console.log("data---", result);
        setUpdate(true);
        setFullWidth(false);
        setUpdateMessage(result.addEditCustomers.message);

        console.log("result---", result);
      }
    },
  });

  const handleClose = () => {
    //setIsOpen(false);
    handleCloseDialog(false);
  };

  const handleRowDelete = () => {};

  const handleReset = (event) => {
    console.log("sdlkksf---", data);
    setname(data.getCustomers.rows[0].name);
    setcontactPerson(data.getCustomers.rows[0].contactPerson);
    setEmail(data.getCustomers.rows[0].email);
    setphoneNo(data.getCustomers.rows[0].phoneNo);
    setusername(data.getCustomers.rows[0].username);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleSubmit = (event) => {
    //alert("A name was submitted: ");

    getUpdateDevice({
      variables: {
        userId: oemTcuInventoryId,
        name: name,
        contactPerson: contactPerson,
        email: Email,
        phoneNo: phoneNo,
        Username: username,
        Password: password,
        status: "ACTIVE",
        ownerId: ownerId,
      },
    });
    event.preventDefault();
  };

  return (
    <React.Fragment>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {!isDelete ? (
            <>
              <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={isDialogOpened}
                aria-labelledby="max-width-dialog-title"
                disableEscapeKeyDown={true}
              >
                <DialogContent>
                  {isUpdate ? (
                    <div style={{ marginTop: "10px", padding: "15px" }}>
                      {updateMessage}

                      <Button
                        style={{ position: "absolute", top: "0", right: "0" }}
                        onClick={handleClose}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={4}>
                        <Grid item xs={9}>
                          <Typography variant="h4">
                            Edit Customer Details
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            style={{ float: "right" }}
                            onClick={handleClose}
                          >
                            <CloseIcon />
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="customerName"
                            label="Customer Name &#42;"
                            multiline
                            maxRows={4}
                            value={name}
                            variant="outlined"
                            fullWidth={true}
                            onChange={(e) => setname(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="email"
                            label="Email &#42;"
                            multiline
                            maxRows={4}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"
                            value={Email}
                            fullWidth={true}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="phoneNumber"
                            label="Phone Number &#42;"
                            multiline
                            maxRows={4}
                            variant="outlined"
                            value={phoneNo}
                            onChange={(e) => setphoneNo(e.target.value)}
                            fullWidth={true}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="contactPerson"
                            label="Contact Person &#42;"
                            multiline
                            maxRows={4}
                            variant="outlined"
                            value={contactPerson}
                            onChange={(e) => setcontactPerson(e.target.value)}
                            fullWidth={true}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="username"
                            label="Username &#42;"
                            multiline
                            maxRows={4}
                            variant="outlined"
                            onChange={(e) => setusername(e.target.value)}
                            value={username}
                            fullWidth={true}
                          />
                        </Grid>
                        {/* <Grid item xs={6}>
                          <TextField
                            id="device_model_name"
                            label="Password"
                            multiline
                            maxRows={4}
                            variant="filled"
                            defaultValue={password}
                            fullWidth={true}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid> */}

                        <Grid className={classes.footerButtonGrid} item xs={12}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            onClick={handleReset}
                            className={classes.footerButton}
                            startIcon={<ReplayIcon />}
                          >
                            Reset
                          </Button>
                          <Button
                            type="submit"
                            variant="outlined"
                            size="large"
                            className={classes.saveButton}
                            startIcon={<SaveIcon />}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
            </>
          ) : (
            <>
              <Dialog
                fullWidth="false"
                maxWidth="sm"
                open={isDialogOpened}
                aria-labelledby="max-width-dialog-title"
                disableEscapeKeyDown={true}
              >
                <DialogTitle
                  style={{ backgroundColor: "#c1c1c1", color: "#333" }}
                  id="alert-dialog-slide-title"
                >
                  DELETE ALERT
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <Typography variant="h5">{deleteMessage}</Typography>
                  </DialogContentText>
                </DialogContent>
                {!isdeleted ? (
                  <DialogActions>
                    <Button
                      style={{ color: "#333" }}
                      variant="contained"
                      onClick={handleClose}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleRowDelete}
                    >
                      AGREE
                    </Button>
                  </DialogActions>
                ) : null}
              </Dialog>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
}
