import React from "react";
import { gql, useMutation } from "@apollo/client";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

const LoginQuery = gql`
  mutation ($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      id
      username
      type
      status
      token
    }
  }
`;

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!sessionStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

async function loginUser(
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError,
  client
) {
  setIsLoading(true);
  let Error = 0;

  const result = await client
    .mutate({
      variables: { username: login, password: password },
      mutation: LoginQuery,
    })
    .catch((err) => (Error = 1));
  //throwErrorIfApolloErrorOccurred(result)

  console.log("login-data----", Error);
  if (Error == 0) {
    if (!!result.data.login) {
      setTimeout(async () => {
        sessionStorage.setItem("id_token", result.data.login.token);
        sessionStorage.setItem("userType", result.data.login.type);
        sessionStorage.setItem("userId", result.data.login.id);
        setError(null);
        setIsLoading(false);
        dispatch({ type: "LOGIN_SUCCESS" });

        //history.push('/app/dashboard')
      }, 100);
    }
  } else {
    console.log("eroorr", Error);
    setError(true);
    setIsLoading(false);
    // setTimeout(() => {
    //   dispatch({ type: "LOGIN_FAILURE" });
    // }, 3000);
  }
}

function signOut(dispatch, history) {
  sessionStorage.removeItem("id_token");
  sessionStorage.removeItem("userType");
  sessionStorage.removeItem("userId");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
