import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import {
  Button,
  Grid,
  Paper,
  Typography,
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CreateIcon from "@material-ui/icons/Create";
import FileDownloadTwoToneIcon from "@material-ui/icons/GetAppTwoTone";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimMaxWidthDialog from "./editSim";
import axios from "axios";

// components
import { client2 } from "../customer/client";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import SIMData_Upload_Sample from "../../images/SIMData-Upload-Sample.xlsx";

const GET_UPLOAD_URL = gql`
  mutation ($fileExtension: String!) {
    getPublicUploadURL(fileExtension: $fileExtension) {
      bucketName
      filename
      publicUploadURL
    }
  }
`;

const GET_DOWNLOAD_URL = gql`
  mutation ($ownerId: Int, $downloadFor: String!) {
    excelFileDownload(ownerId: $ownerId, downloadFor: $downloadFor) {
      downloadUrl
    }
  }
`;

const GET_Customers = gql`
  query (
    $userId: Int
    $limit: Int!
    $offset: Int!
    $search: customerSearchInput
    $ownerId: Int
  ) {
    getCustomers(
      userId: $userId
      limit: $limit
      offset: $offset
      search: $search
      ownerId: $ownerId
    ) {
      totalCount
      rows {
        id
        userId
        name
        contactPerson
        email
        phoneNo
        username
        password
        ownerId
      }
    }
  }
`;

const getSIMData = gql`
  query (
    $id: Int
    $customerId: Int
    $limit: Int!
    $offset: Int!
    $search: simSearchInput
    $ownerId: Int
  ) {
    getSIMInventory(
      id: $id
      customerId: $customerId
      limit: $limit
      offset: $offset
      search: $search
      ownerId: $ownerId
    ) {
      totalCount
      rows {
        id
        customerId
        iccid
        primaryMsisdn
        primaryTspName
        primaryTspActivationDate
        primaryTspExpiryDate
        fallbackMsisdn
        fallbackTspName
        fallbackTspActivationDate
        fallbackTspExpiryDate
        cardState
        whitelistedIps
        status
        ownerId
      }
    }
  }
`;

const excelFileUpload = gql`
  mutation excelFileUpload(
    $fileInfo: FileUploadInput!
    $commonInput: CommonInput!
  ) {
    excelFileUpload(fileInfo: $fileInfo, commonInput: $commonInput) {
      failedToUpload
      totalExcelDataRecords
      totalDuplicateRecords
      successfullyUploaded
      failedUploadList
    }
  }
`;

const columns = [
  {
    id: "ICCID",
    label: "ICCID",
    minWidth: 170,
    align: "center",
  },
  {
    id: "Primary_TSP_Name",
    label: "Primary TSP Name",
    minWidth: 170,
    align: "center",
  },
  {
    id: "Primary_TSP_MSISDN",
    label: "Primary TSP MSISDN",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Primary_TSP_Activation",
    label: "Primary TSP Activation",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Primary_TSP_Expiry",
    label: "Primary TSP Expiry",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Fallback_TSP_Name",
    label: "Fallback TSP Name",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Fallback_TSP_MSISDN",
    label: "Fallback TSP MSISDN",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Fallback_TSP_Activation",
    label: "Fallback TSP Activation",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Fallback_TSP_Expiry",
    label: "Fallback TSP Expiry",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Card_State",
    label: "Card State",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Card_Status",
    label: "Card Status",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

function Charts(props) {
  var theme = useTheme();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editId, setEditId] = React.useState();
  const [oemId, setOemId] = React.useState();
  const [customerIds, setCustomerId] = React.useState();
  const [readPermission, setReadPermission] = useState(false);
  const [custPermission, setCustPermission] = useState(true);
  const [createPermission, setCreatePermission] = useState(true);
  const [updatePermission, setUpdatePermission] = useState(true);
  const [deletePermission, setDeletePermission] = useState(true);
  const [excelError, setExcelerror] = useState();
  const [errorOpen, setErrorOpen] = useState(false);
  const [excelData, setexcelData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchIccid, setSearchIccid] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("#");

  let id;
  let usertype;
  let ownerId;
  if (typeof props.featureId != "undefined") {
    sessionStorage.setItem("userId", props.featureId);
    id = props.featureId;
  } else {
    id = parseInt(sessionStorage.getItem("userId"));
    usertype = sessionStorage.getItem("userType");
    ownerId = parseInt(sessionStorage.getItem("userId"));
  }

  useEffect(() => {
    if ((customerIds, !isOpen)) {
      getSIMInventory({
        variables: {
          limit: rowsPerPage,
          offset: page,
          ownerId: ownerId,
          customerId: customerIds,
        },
      });
    }
  }, [customerIds, !isOpen]);
  //Call the Feature Access Permission on page load
  useEffect(() => {
    if (usertype == "ADMIN") {
      getCustomerData({
        variables: {
          limit: 100,
          offset: 0,
          ownerId: ownerId,
        },
      });
    }

    if (usertype == "ADMIN" && customerIds) {
      getSIMInventory({
        variables: {
          limit: rowsPerPage,
          offset: page,
          ownerId: ownerId,
          customerId: customerIds,
        },
      });
    } else {
      getSIMInventory({
        variables: {
          limit: rowsPerPage,
          offset: page,
          customerId: ownerId,
        },
      });
    }

    if (usertype == "CUSTOMER") {
      setUpdatePermission(false);
      setCustPermission(false);
      setCreatePermission(false);
    }
  }, []);

  const [
    getSIMInventory,
    { loading: SIMLoading, error: SIMError, data: SIMData },
  ] = useLazyQuery(getSIMData, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {},
  });

  const [getCustomerData, { loading, error, data: custData }] = useLazyQuery(
    GET_Customers,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        if (result.getCustomers.rows[0]) {
          console.log("customerIDs----", result.getCustomers.rows[0].userId);
          setCustomerId(parseInt(result.getCustomers.rows[0].userId));
        }
      },
    }
  );

  const [
    excelDownload,
    { loading: SIMExcelLoading, error: SIMExcelError, data: SIMExcelData },
  ] = useMutation(GET_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      window.open(result.excelFileDownload.downloadUrl);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (usertype == "ADMIN") {
      getSIMInventory({
        variables: {
          limit: rowsPerPage,
          offset: page,
          ownerId: ownerId,
          customerId: customerIds,
        },
      });
    } else {
      getSIMInventory({
        variables: {
          limit: rowsPerPage,
          offset: page,
          customerId: ownerId,
        },
      });
    }
  };

  const handleOpen = (id, oemId) => {
    setIsOpen(!isOpen);
    setEditId(id);
    setOemId(props.oem);
    console.log("id---", id);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    console.log("rowsperPage---", rowsPerPage);
    if (usertype == "ADMIN") {
      getSIMInventory({
        variables: {
          limit: event.target.value,
          offset: page,
          ownerId: ownerId,
          customerId: customerIds,
        },
      });
    } else {
      getSIMInventory({
        variables: {
          limit: rowsPerPage,
          offset: page,
          customerId: ownerId,
        },
      });
    }
  };

  const handleSearch = (event) => {
    if (usertype == "ADMIN") {
      getSIMInventory({
        variables: {
          limit: rowsPerPage,
          offset: page,
          search: {
            iccid: searchIccid,
          },
          ownerId: ownerId,
          customerId: customerIds,
        },
      });
    } else {
      getSIMInventory({
        variables: {
          limit: rowsPerPage,
          offset: page,
          search: {
            iccid: searchIccid,
          },
          customerId: ownerId,
        },
      });
    }
  };

  const [
    excelUpload,
    { loading: excelLoading, error: excelerror, data: exceldata },
  ] = useMutation(excelFileUpload, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setExcelError(`Message: ${message}`);
          props.closeDialog(false);
          props.setExcelData(null);
          props.setUpload(false);
          props.setMessage(`Message: ${message}`);
          setTimeout(() => {
            props.setSnackbar(false);
          }, 3000);
        });
      }
    },
    onCompleted: (ressss) => {
      console.log(ressss);
      props.setMessage(
        "The file data has been uploaded. Click on 'Done' to view the result."
      );
      props.setUpload(true);

      //props.closeDialog(true);

      props.setExcelData(ressss.excelFileUpload);

      getSIMInventory({
        variables: {
          limit: rowsPerPage,
          offset: page,
          ownerId: ownerId,
          customerId: customerIds,
        },
      });
    },
  });

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const onUpload = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    console.log("file----", file);
    console.log("validdiiitt---", validity);
    if (file) {
      //setUpload(true);
      //setProgressDialog(true);

      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setUpload(false);
      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );

        console.log("fileExtension---", fileExtension);

        const response = await client2.mutate({
          mutation: GET_UPLOAD_URL,
          variables: {
            fileExtension,
          },
        });
        //console.log("response----", response.data);
        if (response.data && response.data.getPublicUploadURL) {
          console.log("response----", response.data);
          const url = response.data.getPublicUploadURL.publicUploadURL;

          await axios.put(url, file, {
            onUploadProgress: (data) => {
              //Set the progress value to show the progress bar
              let percentage = Math.round((100 * data.loaded) / data.total);
              props.setMessage(`Uploading SIM ${percentage} %`);
              props.setSnackbar(true);
            },
          });

          excelUpload({
            variables: {
              fileInfo: {
                uploadFor: "SIMDetailsUpload",
                bucketName: response.data.getPublicUploadURL.bucketName,
                fileName: response.data.getPublicUploadURL.filename,
              },
              commonInput: {
                ownerId: ownerId,
                customerId: customerIds,
              },
            },
          });
        }
      }
    } else {
      console.log("file----", file);
      alert("No file selected");
    }
  };

  const onDownload = (event) => {
    if (usertype == "CUSTOMER") {
      excelDownload({
        variables: {
          ownerId: ownerId,
          downloadFor: "SIM",
        },
      });
    } else {
      excelDownload({
        variables: {
          ownerId: customerIds,
          downloadFor: "SIM",
        },
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography
            style={{ marginTop: "10px", marginBottom: "25px" }}
            variant="h4"
          >
            SIM Management
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {custData && customerIds && custPermission && (
            <FormControl variant="outlined" fullWidth={true} size="small">
              <InputLabel id="sim_status1">Customers</InputLabel>
              <Select
                onChange={(e) => {
                  setCustomerId(e.target.value);
                }}
                labelId="customerId"
                id="customerId"
                label="Customers"
                value={customerIds}
              >
                {custData &&
                  custData.getCustomers.rows.map((item) => (
                    <MenuItem value={item.userId}>{item.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Grid>

        {SIMLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid item xs={4}>
              <TextField
                id="search_iccid"
                label="Search ICCID"
                variant="outlined"
                value={searchIccid}
                style={{ width: "100%" }}
                size="small"
                onChange={(e) => {
                  setSearchIccid(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="outlined"
                component="span"
                style={{ float: "right" }}
                onClick={handleSearch}
              >
                Search
              </Button>
            </Grid>
            {createPermission ? (
              <>
                <Grid item xs={7}>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <input
                          accept="xlsx/*"
                          className={classes.input}
                          id="simInput-file"
                          type="file"
                          onChange={onUpload}
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                        />
                        <label htmlFor="simInput-file">
                          <Button
                            type="submit"
                            variant="outlined"
                            className={classes.button}
                            component="span"
                            style={{ float: "right" }}
                            // onClick={() => setSnackbar("Downloading")}
                          >
                            Upload Template
                          </Button>
                        </label>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          href={SIMData_Upload_Sample}
                          variant="outlined"
                          className={classes.sampleButton}
                          download="SIM-Upload-Sample"
                          style={{ float: "right" }}
                          //onClick={() => setSnackbar("Downloading")}
                        >
                          Download Template
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </>
            ) : null}
            <Grid item xs={12}>
              {SIMExcelLoading ? (
                <Button
                  variant="outlined"
                  className={classes.exportButton}
                  style={{ float: "right" }}
                >
                  Exporting...
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={classes.exportButton}
                  style={{ float: "right" }}
                  startIcon={<FileDownloadTwoToneIcon />}
                  onClick={onDownload}
                >
                  Export
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <>
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          </>
                        ))}

                        {updatePermission ? (
                          <TableCell
                            align="center"
                            style={{ minWidth: "170px" }}
                          >
                            Action
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {SIMLoading ? <CircularProgress /> : null}
                      {SIMData && SIMData.getSIMInventory.totalCount > 0 ? (
                        SIMData.getSIMInventory.rows.map((row) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1}>
                              <TableCell align="center">{row.iccid}</TableCell>
                              <TableCell align="center">
                                {row.primaryTspName}
                              </TableCell>
                              <TableCell align="center">
                                {row.primaryMsisdn}
                              </TableCell>
                              <TableCell align="center">
                                {row.primaryTspActivationDate}
                              </TableCell>
                              <TableCell align="center">
                                {row.primaryTspExpiryDate}
                              </TableCell>
                              <TableCell align="center">
                                {row.fallbackTspName}
                              </TableCell>
                              <TableCell align="center">
                                {row.fallbackMsisdn}
                              </TableCell>
                              <TableCell align="center">
                                {row.fallbackTspActivationDate}
                              </TableCell>
                              <TableCell align="center">
                                {row.fallbackTspExpiryDate}
                              </TableCell>
                              <TableCell align="right">
                                {row.cardState}
                              </TableCell>
                              <TableCell align="center">{row.status}</TableCell>
                              {updatePermission ? (
                                <TableCell align="center">
                                  {updatePermission ? (
                                    <>
                                      <Button
                                        onClick={() => handleOpen(row.id)}
                                      >
                                        <CreateIcon />
                                      </Button>
                                    </>
                                  ) : null}
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell>No Records found!</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                {SIMData && SIMData.getSIMInventory.totalCount > 0 ? (
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={SIMData ? SIMData.getSIMInventory.totalCount : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                ) : null}
              </Paper>
            </Grid>
            {isOpen ? (
              <SimMaxWidthDialog
                isDialogOpened={isOpen}
                handleCloseDialog={() => setIsOpen(false)}
                simInventoryId={editId}
                oemTcuInventoryId={oemId}
                ownerId={ownerId}
              />
            ) : null}
          </>
        )}
      </Grid>
    </>
  );
}

export default withSnackbar(Charts);
// ################################################################
