import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SaveIcon from "@material-ui/icons/Save";
import ReplayIcon from "@material-ui/icons/Replay";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

// styles
import useStyles from "./styles";

const getSIMData = gql`
  query (
    $id: Int
    $customerId: Int
    $limit: Int!
    $offset: Int!
    $search: simSearchInput
    $ownerId: Int
  ) {
    getSIMInventory(
      id: $id
      customerId: $customerId
      limit: $limit
      offset: $offset
      search: $search
      ownerId: $ownerId
    ) {
      totalCount
      rows {
        id
        customerId
        iccid
        primaryMsisdn
        primaryTspName
        primaryTspActivationDate
        primaryTspExpiryDate
        fallbackMsisdn
        fallbackTspName
        fallbackTspActivationDate
        fallbackTspExpiryDate
        cardState
        whitelistedIps
        status
        ownerId
      }
    }
  }
`;

const updateDevice = gql`
  mutation (
    $id: Int!
    $iccid: String!
    $primaryMsisdn: String!
    $primaryTspName: String!
    $primaryTspActivationDate: String!
    $primaryTspExpiryDate: String!
    $fallbackMsisdn: String!
    $fallbackTspName: String!
    $fallbackTspActivationDate: String!
    $fallbackTspExpiryDate: String!
    $cardState: String!
    $status: String!
    $ownerId: Int
  ) {
    addEditSIMInventory(
      id: $id
      iccid: $iccid
      primaryMsisdn: $primaryMsisdn
      primaryTspName: $primaryTspName
      primaryTspActivationDate: $primaryTspActivationDate
      primaryTspExpiryDate: $primaryTspExpiryDate
      fallbackMsisdn: $fallbackMsisdn
      fallbackTspName: $fallbackTspName
      fallbackTspActivationDate: $fallbackTspActivationDate
      fallbackTspExpiryDate: $fallbackTspExpiryDate
      cardState: $cardState
      status: $status
      ownerId: $ownerId
    ) {
      message
    }
  }
`;

const simStatusPre = [
  {
    id: "0",
    value: "BOOTSTRAP",
  },
  {
    id: "1",
    value: "COMMERCIALLY ACTIVATED",
  },
];

export default function SimMaxWidthDialog({
  isDialogOpened,
  handleCloseDialog,
  simInventoryId,
  ownerId,
}) {
  console.log("tcuInventoryId---", simInventoryId);

  //const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth] = useState("lg");
  const [customerId, setcustomerId] = useState("");
  const [primaryMsisdn, setprimaryMsisdn] = useState("");
  const [primaryTspName, setprimaryTspName] = useState("");
  const [primaryTspActivationDate, setprimaryTspActivationDate] = useState("");
  const [primaryTspExpiryDate, setprimaryTspExpiryDate] = useState("");
  const [fallbackMsisdn, setfallbackMsisdn] = useState("");
  const [iccid, seticcid] = useState("");
  const [fallbackTspName, setfallbackTspName] = useState("");
  const [fallbackTspActivationDate, setfallbackTspActivationDate] =
    useState("");
  const [fallbackTspExpiryDate, setfallbackTspExpiryDate] = useState("");
  const [cardState, setcardState] = useState("");
  const [status, setSimStatus] = useState("");
  const [isUpdate, setUpdate] = useState(false);
  const [updateMessage, setUpdateMessage] = useState();
  const classes = useStyles();

  //Fetch the imei details using the id passed through props
  const [getSIMInventory, { loading, error, data }] = useLazyQuery(getSIMData, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      if (result) {
        result.getSIMInventory.rows.map((item) => {
          setcustomerId(item.customerId);
          setprimaryMsisdn(item.primaryMsisdn);
          setprimaryTspName(item.primaryTspName);
          setprimaryTspActivationDate(item.primaryTspActivationDate);
          setprimaryTspExpiryDate(item.primaryTspExpiryDate);
          setfallbackMsisdn(item.fallbackMsisdn);
          seticcid(item.iccid);
          setfallbackTspName(item.fallbackTspName);
          setfallbackTspActivationDate(item.fallbackTspActivationDate);
          setfallbackTspExpiryDate(item.fallbackTspExpiryDate);
          setcardState(item.cardState);
          if (item.status == "COMMERCIALLY ACTIVATED") {
            setSimStatus(1);
          } else {
            setSimStatus(0);
          }
        });
      }
    },
  });

  const [
    getUpdateDevice,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useMutation(updateDevice, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          setUpdate(true);
          setFullWidth(false);
          setUpdateMessage(message);
        });
      }
    },
    onCompleted: (result) => {
      if (result) {
        console.log("data---", result);
        setUpdate(true);
        setFullWidth(false);
        setUpdateMessage(result.addEditSIMInventory.message);

        console.log("result---", result);
      }
    },
  });

  const handleClose = () => {
    //setIsOpen(false);
    handleCloseDialog(false);
  };

  /* const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  }; */

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleReset = (event) => {
    setprimaryMsisdn(data.getSIMInventory.rows[0].primaryMsisdn);
    setprimaryTspName(data.getSIMInventory.rows[0].primaryTspName);
    setprimaryTspActivationDate(
      data.getSIMInventory.rows[0].primaryTspActivationDate
    );
    setprimaryTspExpiryDate(data.getSIMInventory.rows[0].primaryTspExpiryDate);
    setfallbackMsisdn(data.getSIMInventory.rows[0].fallbackMsisdn);
    seticcid(data.getSIMInventory.rows[0].iccid);
    setfallbackTspName(data.getSIMInventory.rows[0].fallbackTspName);
    setfallbackTspActivationDate(
      data.getSIMInventory.rows[0].fallbackTspActivationDate
    );
    setfallbackTspExpiryDate(
      data.getSIMInventory.rows[0].fallbackTspExpiryDate
    );
    setcardState(data.getSIMInventory.rows[0].cardState);
    if (data.getSIMInventory.rows[0].status == "COMMERCIALLY ACTIVATED") {
      setSimStatus(1);
    } else {
      setSimStatus(0);
    }
  };

  const handleSubmit = (event) => {
    //alert("A name was submitted: ");
    let statusString;
    if (status == 0) {
      statusString = "BOOTSTRAP";
    } else if (status == 1) {
      statusString = "COMMERCIALLY ACTIVATED";
    }
    getUpdateDevice({
      variables: {
        id: simInventoryId,
        iccid: iccid,
        primaryMsisdn: primaryMsisdn,
        primaryTspName: primaryTspName,
        primaryTspActivationDate: primaryTspActivationDate,
        primaryTspExpiryDate: primaryTspExpiryDate,
        fallbackMsisdn: fallbackMsisdn,
        fallbackTspName: fallbackTspName,
        fallbackTspActivationDate: fallbackTspActivationDate,
        fallbackTspExpiryDate: fallbackTspExpiryDate,
        cardState: cardState,
        status: statusString,
        ownerId: ownerId,
      },
    });
    event.preventDefault();
  };

  useEffect(() => {
    console.log("ldjklsdjfks");
    getSIMInventory({
      variables: {
        id: simInventoryId,
        limit: 1,
        offset: 0,
      },
    });
  }, []);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isDialogOpened}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="max-width-dialog-title"></DialogTitle>
        <DialogContent>
          {isUpdate ? (
            <div style={{ marginTop: "10px", padding: "15px" }}>
              {updateMessage}
              <Button
                style={{ position: "absolute", top: "0", right: "0" }}
                onClick={handleClose}
              >
                <CloseIcon />
              </Button>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={9}>
                  <Typography variant="h4">Edit SIM Details</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button onClick={handleClose} style={{ float: "right" }}>
                    <CloseIcon />
                  </Button>
                </Grid>
                {loading ? (
                  <Grid item xs={12}>
                    <CircularProgress
                      color="inherit"
                      size="40"
                      thickness="3.6"
                      style={{ width: "100px", marginLeft: "50%" }}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={3}>
                      <TextField
                        id="iccid"
                        label="ICCID"
                        maxRows={4}
                        value={iccid}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        required
                        onChange={(e) => {
                          seticcid(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="primary_msisdn"
                        label="Primary MSISDN"
                        maxRows={4}
                        variant="outlined"
                        size="small"
                        value={primaryMsisdn}
                        fullWidth={true}
                        required
                        onChange={(e) => {
                          setprimaryMsisdn(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="primary_tsp_name"
                        label="Primary TSP Name"
                        maxRows={4}
                        variant="outlined"
                        size="small"
                        value={primaryTspName}
                        fullWidth={true}
                        required
                        onChange={(e) => {
                          setprimaryTspName(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="primary_activation_date"
                        label="Primary TSP Activation Date"
                        maxRows={4}
                        variant="outlined"
                        size="small"
                        value={primaryTspActivationDate}
                        fullWidth={true}
                        required
                        onChange={(e) => {
                          setprimaryTspActivationDate(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="primary_expiry_date"
                        label="Primary TSP Expiry Date"
                        maxRows={4}
                        variant="outlined"
                        size="small"
                        value={primaryTspExpiryDate}
                        fullWidth={true}
                        required
                        onChange={(e) => {
                          setprimaryTspExpiryDate(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="fallback_msisdn"
                        label="Fallback MSISDN"
                        maxRows={4}
                        variant="outlined"
                        size="small"
                        value={fallbackMsisdn}
                        fullWidth={true}
                        required
                        onChange={(e) => {
                          setfallbackMsisdn(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="fallback_tsp_name"
                        label="Fallback TSP Name"
                        maxRows={4}
                        variant="outlined"
                        size="small"
                        value={fallbackTspName}
                        fullWidth={true}
                        required
                        onChange={(e) => {
                          setfallbackTspName(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="fallback_activation_date"
                        label="Fallback TSP Activation Date"
                        maxRows={4}
                        variant="outlined"
                        size="small"
                        value={fallbackTspActivationDate}
                        fullWidth={true}
                        required
                        onChange={(e) => {
                          setfallbackTspActivationDate(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="fallback_expiry_date"
                        label="Fallback TSP Expiry Date"
                        maxRows={4}
                        variant="outlined"
                        size="small"
                        value={fallbackTspExpiryDate}
                        fullWidth={true}
                        required
                        onChange={(e) => {
                          setfallbackTspExpiryDate(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        variant="outlined"
                        fullWidth="true"
                        size="small"
                      >
                        <InputLabel id="sim_status1">SIM Status</InputLabel>
                        <Select
                          onChange={(e) => {
                            setSimStatus(e.target.value);
                          }}
                          labelId="sim_status1"
                          id="sim_status"
                          label="SIM Status"
                          value={status}
                          required
                        >
                          {simStatusPre.map((item) => (
                            <MenuItem value={item.id}>{item.value}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid
                      justifyContent="flex-end"
                      className={classes.footerButtonGrid}
                      item
                      xs={12}
                    >
                      <div style={{ float: "right" }}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="large"
                          className={classes.footerButton}
                          startIcon={<ReplayIcon />}
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                        <Button
                          type="submit"
                          variant="outlined"
                          size="large"
                          className={classes.saveButton}
                          startIcon={<SaveIcon />}
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
