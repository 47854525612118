import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import AlertDialog from "../Dialog/DialogAlert";
import AlertDialogCustomer from "../Dialog/DialogAlertCustomer";
import { CircularProgress, Button } from "@material-ui/core";
import { useUserState } from "../../context/UserContext";

const diff = Math.random() * 10;
const diff2 = Math.random() * 10;
const token = sessionStorage.getItem("id_token");
export const SnackbarContext = React.createContext();

export class SnackbarContextProvider extends Component {
  state = {
    open: false,
    message: "Uploading",
    isUploaded: false,
    isDialogVisible: false,
    excelData: null,
    excelError: "",
    isCustomer: false,
  };

  setSnackbar = (open) => {
    this.setState({ open });
  };

  setMessage = (message) => {
    console.log("Message----", message);
    this.setState({ message });
  };

  setUpload = (isUploaded) => {
    console.log("Is_Uploaded----", isUploaded);
    this.setState({ isUploaded });
  };

  setExcelError = (excelError) => {
    this.setState({ excelError });
  };

  setExcelData = (excelData) => {
    this.setState({ excelData });
  };

  setIsCustomer = (isCustomer) => {
    this.setState({ isCustomer });
  };

  closeDialog = (isDialogVisible) => this.setState({ isDialogVisible });

  render() {
    const { children } = this.props;
    const {
      message,
      open,
      isUploaded,
      excelError,
      excelData,
      isDialogVisible,
      isCustomer,
    } = this.state;

    return (
      <SnackbarContext.Provider
        value={{
          message: message,
          open: open,
          isDialogVisible: isDialogVisible,
          isUploaded: isUploaded,
          setMessage: this.setMessage,
          setUpload: this.setUpload,
          setSnackbar: this.setSnackbar,
          closeDialog: this.closeDialog,
          setExcelError: this.setExcelError,
          setExcelData: this.setExcelData,
          setIsCustomer: this.setIsCustomer,
        }}
      >
        {open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
            message={message}
            action={
              isUploaded ? (
                <Button
                  style={{ float: "right", color: "white" }}
                  color="inherit"
                  variant="text"
                  onClick={() => {
                    this.closeDialog(true);
                    this.setSnackbar(false);
                  }}
                >
                  Done
                </Button>
              ) : (
                <CircularProgress color="primary" size={25} />
              )
            }
          />
        )}
        {/* {open && (
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              right: "25px",
              width: "30%",
              zIndex: "1400",
              border: "2px solid #F3F3F3",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                padding: "5px",
                backgroundColor: "#212121",
                boxShadow: "1px 1px 1px 1px #F3F3F3",
                borderRadius: "10px",
                color: "white",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="body1">{message}</Typography>
              </Grid>
              <Grid item xs={12}>
                {!isUploaded ? (
                  <CircularProgress />
                ) : (
                  <Button
                    style={{ float: "right", color: "white" }}
                    variant="text"
                    color="inherit"
                    onClick={() => this.closeDialog(true)}
                  >
                    Done
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        )} */}
        {isDialogVisible && isCustomer && (
          <AlertDialogCustomer
            isDialogOpened={isDialogVisible}
            handleCloseDialog={() => {
              this.setState({ open: false });
              this.closeDialog(false);
            }}
            errorData={excelError}
            excelData={excelData}
          />
        )}
        {isDialogVisible && !isCustomer && (
          <AlertDialog
            isDialogOpened={isDialogVisible}
            handleCloseDialog={() => {
              this.setState({ open: false });
              this.closeDialog(false);
            }}
            errorData={excelError}
            excelData={excelData}
          />
        )}
        {children}
      </SnackbarContext.Provider>
    );
  }
}

export const SnackbarConsumer = SnackbarContext.Consumer;
