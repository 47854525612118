import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  Checkbox,
  withStyles,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
import myGif from "./ViBanner.gif";
import LogoFixed from "./LogoFixed.svg";
import google from "../../images/google.svg";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

const site_Key = process.env.REACT_APP_CAPTCHA_SITE_KEY;

export const CustomCheckBox = withStyles({
  root: {
    color: "#cccccc",
    transform: "scale(1.1)",

    "&$checked": {
      color: "#0AA9F3",
    },
  },
  checked: {},
  multilineColor: {
    color: "red",
  },
})((props) => <Checkbox color="default" {...props} />);

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [catpcha, setCaptcha] = useState(true);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleCaptchaChange = () => {
    setCaptcha(false);
  };

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const client = useApolloClient();

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <div className={classes.loginLogo}>
            <img src={LogoFixed} alt="logo" className={classes.logotypeImage} />
          </div>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography color="primary">
                Welcome Back! Login to continue
              </Typography>

              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type={values.showPassword ? "text" : "password"}
                fullWidth
              />
              <IconButton
                className={classes.passwordIcon}
                onClick={handleClickShowPassword}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <Grid item className={classes.paddingThirdInputField}>
                <Grid
                  container
                  justify="center"
                  className={classes.paddingCaptcha}
                >
                  <ReCAPTCHA
                    sitekey={site_Key}
                    onChange={() => handleCaptchaChange()}
                    theme="dark"
                  />
                </Grid>
              </Grid>
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 ||
                      passwordValue.length === 0 ||
                      catpcha
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                        client
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}
                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    Invalid Username/ Password
                  </Typography>
                </Fade>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className={classes.logotypeContainer}>
        <img
          src={myGif}
          alt="logo"
          className={classes.logotypeImage}
          width={100}
        />
      </div>
    </Grid>
  );
}

export default withRouter(Login);
